import React, { useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Sidebar from "./sidebar"

export interface GallerySidebarData {
  isOpen: boolean;
  onClose: () => void,
  location: any
}

export default function GallerySidebar({ isOpen, onClose, location }: GallerySidebarData) {
  const locationSplitted = location.pathname.split('/')
  const [activeCategory, setActiveCategory] = useState('') 
  const data = useStaticQuery(graphql`
    query {
      gallerySidebar {
        items
      }
    }
  `)

  const gallerySidebar = useMemo(() => {
    return JSON.parse(data.gallerySidebar.items)
  }, [data.gallerySidebar.items])

  return (
    <Sidebar
      title="Gallery"
      items={gallerySidebar}
      asLink={true}
      isOpen={isOpen}
      onClose={onClose}
      onItemClick={id => setActiveCategory(id)}
      isItemOpen={id => {
        // If its the first segment (splitted "/" should be 3), example /gallery/1980, /gallery/1990 etc.
        // then check if it's an exact math
        // else check if it startsWith
        if (locationSplitted.length === 3) {
          return id === location.pathname
        }
        return location.pathname.startsWith(id)
      }}
      isItemActive={id => {
        if (activeCategory) {
          return id === activeCategory
        }
        return id === location.pathname
          || `${id}/` === location.pathname
      }}
    />
  )
}
