import React from "react"
import {
  Divider,
  makeStyles,
  Theme,
  Box,
  Typography,
  List,
  createStyles,
  Hidden,
  Drawer,
} from "@material-ui/core"
import SidebarListItem from "./sidebar-list-item"
import { SidebarItemData } from "../utils"

const drawerWidth = 300

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up("md")]: {
        top: "64px",
        height: "calc(100% - 64px)",
      },
    },
  })
)

export interface SidebarData {
  title: string
  items: SidebarItemData[]
  asLink?: boolean
  isOpen: boolean
  onClose: () => void
  isItemActive?: (id: string) => boolean
  isItemOpen?: (id: string) => boolean
  onItemClick: (id: string) => void
}

export default function Sidebar(data: SidebarData) {
  const classes = useStyles()
  const listItems = (
    <List disablePadding>
      {data.items.map((sidebarItem: SidebarItemData, index: number) => (
        <React.Fragment key={`${sidebarItem.id}${index}`}>
          <SidebarListItem
            depthStep={10}
            depth={0}
            item={sidebarItem}
            asLink={data.asLink}
            isActive={data.isItemActive}
            isOpen={data.isItemOpen}
            onClick={data.onItemClick}
          />
          <Divider />
        </React.Fragment>
      ))}
    </List>
  )

  return (
    <nav className={classes.drawer}>
      <Hidden smUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={data.isOpen}
          onClose={data.onClose}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          <Box p={6}>
            <Typography variant="h4" color="primary">
              {data.title}
            </Typography>
          </Box>
          {listItems}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          {listItems}
        </Drawer>
      </Hidden>
    </nav>
  )
}
