import React from "react"

// @ts-ignore
import videos from "../../data/videos.yaml"

import { SidebarItemData, scrollTo, stringToAnchorId } from "../utils"
import { VideoEntry } from "../pages/videos"

import Sidebar from "./sidebar"

export interface VideosSidebarData {
  isOpen: boolean
  onClose: () => void
}

export default function VideosSidebar({ isOpen, onClose }: VideosSidebarData) {
  const sidebarItems: SidebarItemData[] = videos.map((video: VideoEntry) => {
    return {
      id: video.category,
      name: video.category,
      label: `${video.category} (${video.items.length})`
    }
  })

  return (
    <Sidebar
      title="Videos"
      items={sidebarItems}
      isOpen={isOpen}
      onClose={onClose}
      onItemClick={id => scrollTo(stringToAnchorId(id), -100)}
    />
  )
}
