import React from "react"
import {
  Theme,
  makeStyles,
  createStyles,
  ThemeProvider,
} from "@material-ui/core"

import Header from "../components/header"
import theme from "../theme"
import { PageProps } from "gatsby"
import GallerySidebar from "../components/gallery-sidebar"
import VideosSidebar from "../components/videos-sidebar"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    titleBar: {
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",
      },
    },
  })
)

const Layout = ({ children, pageContext, location }: PageProps) => {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const { layout } = pageContext as any
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  // Sidebar
  let sidebar = <></>
  let menuOnClick
  if (layout == "gallery") {
    menuOnClick = handleDrawerToggle
    sidebar = (
      <GallerySidebar
        isOpen={mobileOpen}
        onClose={handleDrawerToggle}
        location={location}
      />
    )
  } else if (layout == "videos") {
    menuOnClick = handleDrawerToggle
    sidebar = <VideosSidebar isOpen={mobileOpen} onClose={handleDrawerToggle} />
  }

  return (
    <ThemeProvider theme={theme}>
      <Header menuOnClick={menuOnClick} />
      <div className={classes.root}>
        {sidebar}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </ThemeProvider>
  )
}
export default Layout
