import React, { useState } from "react"
import { navigate } from "gatsby"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"

export interface SearchDialogProps {
  isOpen: boolean
  onClose: () => void
}

export const SearchDialog = ({ onClose, isOpen }: SearchDialogProps) => {
  const [fromDate, setFromDate] = useState(new Date("1920-08-18T21:11:54"))
  const [toDate, setToDate] = useState(new Date())
  const [value, setValue] = useState("")

  const handleSearch = () => {
    navigate(
      `/search?query=${value}&from=${fromDate.getTime()}&to=${toDate.getTime()}`
    )
    onClose()
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Search</DialogTitle>
      <DialogContent>
        <TextField
          name="search"
          fullWidth
          placeholder="Search"
          variant="filled"
          label="Search"
          autoFocus
          onChange={(e: any) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box mt={3} display="flex">
            <KeyboardDatePicker
              format="MM/dd/yyyy"
              margin="normal"
              label="From date"
              value={fromDate}
              onChange={d => setFromDate(d as Date)}
              onKeyDown={handleKeyDown}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              margin="normal"
              label="To date"
              format="MM/dd/yyyy"
              value={toDate}
              onChange={d => setToDate(d as Date)}
              onKeyDown={handleKeyDown}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Box>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSearch} color="primary">
          Search
        </Button>
      </DialogActions>
    </Dialog>
  )
}
