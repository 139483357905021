import deepmerge from "deepmerge"

export const scrollTo = (id: string, offset: number = 0) => {
  const element = document.getElementById(id)
  if (element) {
    window.scrollTo({
      top: element.offsetTop + offset,
      left: 0,
      behavior: "smooth",
    })
  }
}

export const stringToAnchorId = (str: string) => {
  return str.replace(/\s/g, "-").toLowerCase()
}

export const youtubeUrl = (id: string) => {
  return `https://www.youtube.com/watch?v=${id}`
}

export const youtubeImageUrl = (id: string) => {
  return `https://img.youtube.com/vi/${id}/mqdefault.jpg`
}

export function makeId(length: number) {
  let result = ""
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export interface SidebarItemData {
  id: string
  label: string
  isActive?: boolean
  items?: SidebarItemData[]
  onClick: (item: SidebarItemData) => void
}

/**
 * Used to convert the optimized node to gallery items
 * @param node
 */
export const toGalleryNode = (node: any) => {
  const replacePrefix = (p: any) =>
    p.replace(new RegExp("{p}", "g"), `/static/${node.f}/`)
  const result: any = {
    id: node.id,
    mtimeMs: node.m,
    name: node.n,
    relativePath: node.rp,
    extension: node.e,
    publicURL: replacePrefix(node.pu),
  }

  if (node.t && Object.keys(node.t).length > 0) {
    result.thumbs = {
      fluid: {
        aspectRatio: node.t.f.ar,
        src: replacePrefix(node.t.f.s),
        srcSet: replacePrefix(node.t.f.ss),
        sizes: node.t.f.si,
      },
    }
  }

  return result
}
