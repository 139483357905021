import React, { useState } from "react"
import {
  makeStyles,
  Theme,
  ListItem,
  Typography,
  List,
  Collapse,
} from "@material-ui/core"
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons"
import { Link } from "gatsby"
import { SidebarItemData, makeId } from "../utils"

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    color: "initial",
    "&.active": {
      background: "#f0f2ff",
      color: theme.palette.primary.main,
      fontWeight: "900",
    },
  },
  menuLabel: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

export interface SidebarListItemData {
  depthStep: number
  depth: number
  item: SidebarItemData
  asLink?: boolean
  isActive?: (name: string) => boolean
  isOpen?: (name: string) => boolean
  onClick?: (name: string) => void
}

export default function SidebarListItem({
  depthStep = 10,
  depth = 0,
  item,
  asLink = false,
  isActive = () => false,
  isOpen = () => false,
  onClick = () => {},
}: SidebarListItemData) {
  const classes = useStyles()
  const { label, id, items } = item
  const [ collapsed, setCollapsed ] = useState(isOpen(id))

  const onItemClick = () => {
    setCollapsed(!collapsed)
    onClick(item.id)
  }

  // Expand icon
  let expandIcon
  if (Array.isArray(items) && items.length) {
    const className = "sidebar-item-expand-arrow"
    expandIcon = collapsed ? (
      <KeyboardArrowDown
        className={className + "sidebar-item-expand-arrow-expanded"}
      />
    ) : (
      <KeyboardArrowRight className={className} />
    )
  }

  const labelElInner = (
    <div className={classes.menuLabel} style={{ paddingLeft: depth * depthStep }}>
      <div>
        <Typography>{label}</Typography>
      </div>
      <div>{expandIcon}</div>
    </div>
  )

  // Label
  const labelEl = asLink
    ? (
      <ListItem 
        component={Link} 
        onClick={onItemClick} 
        to={id} 
        className={`sidebar-item ${isActive(id) ? "active" : ""} ${classes.menuItem}`}>
        {labelElInner}
      </ListItem>
    )
    : (
      <ListItem
        onClick={onItemClick}
        button
        className={`sidebar-item ${isActive(id) ? "active" : ""} ${classes.menuItem}`}>
        {labelElInner}
      </ListItem>
    )

  const el = (
    <>
      {labelEl}
      <Collapse in={collapsed} >
        {Array.isArray(items) ? (
          <List disablePadding>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.id}${index}`}>
                <SidebarListItem
                  depth={depth + 1}
                  depthStep={depthStep}
                  item={subItem}
                  asLink={asLink}
                  isActive={isActive}
                  isOpen={isOpen}
                  onClick={onClick}
                />
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  )

  // Rerender when it has children or isOpen changed
  const _isOpen = () => {
    return collapsed && (items || []).length > 0
      ? makeId(3)
      : collapsed
  }
  
  return React.useMemo(() => el, [isActive(id), _isOpen()])
}
