import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Theme,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { dirToGalleryPath } from "../utils/path-helper"
import { SearchDialog } from "./search-dialog"

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  a: {
    cursor: "pointer",
    color: theme.palette.grey[800],
    "&.active": {
      color: theme.palette.primary.main,
      fontWeight: "900",
    },
  },
}))

const Header = ({ menuOnClick }: { menuOnClick?: () => void | undefined }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [searchIsOpen, setSearchIsOpen] = React.useState(false)
  const classes = useStyles()
  const { site, galleryItem } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      galleryItem: allDirectory(
        limit: 1
        filter: {
          sourceInstanceName: { eq: "gallery" }
          relativePath: { ne: "" }
        }
        sort: { fields: relativePath }
      ) {
        edges {
          node {
            relativePath
          }
        }
      }
    }
  `)

  const isActive = (activePrefix: string) => ({ location }: any) => {
    return location.pathname.startsWith(activePrefix)
      ? { className: `${classes.a} active` }
      : { className: `${classes.a}` }
  }

  const menuItems = [
    {
      label: "GALLERY",
      to: dirToGalleryPath(galleryItem.edges[0].node.relativePath),
      activePrefix: "/gallery",
    },
    { label: "VIDEOS", to: "/videos/", activePrefix: "/videos" },
    { label: "ABOUT US", to: "/about/", activePrefix: "/about" },
  ]

  const toggleMobileSidebar = () => {
    setMobileOpen(!mobileOpen)
  }

  const mobileDrawer = (
    <Drawer
      variant="temporary"
      anchor="right"
      open={mobileOpen}
      onClose={toggleMobileSidebar}
      ModalProps={{ keepMounted: true }}
    >
      <List>
        <ListItem button>
          <a className={classes.a} onClick={() => setSearchIsOpen(true)}>
            SEARCH
          </a>
        </ListItem>
        {menuItems.map(item => (
          <ListItem button key={"DrawerMenu-" + item.to}>
            <Link getProps={isActive(item.activePrefix)} to={item.to}>
              <ListItemText primary={item.label} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )

  const navigation = (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      width="100%"
    >
      <Box px={2}>
        <a className={classes.a} onClick={() => setSearchIsOpen(true)}>
          SEARCH
        </a>
      </Box>
      {menuItems.map(item => (
        <Box px={2} key={item.to}>
          <Link getProps={isActive(item.activePrefix)} to={item.to}>
            {item.label}
          </Link>
        </Box>
      ))}
    </Box>
  )

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        elevation={2}
        style={{ background: "#fff" }}
      >
        <Toolbar className={classes.toolbar}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex" alignItems="center">
              {menuOnClick != null && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={menuOnClick}
                  className="hide-sm-up"
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Box fontWeight="fontWeightBold">
                <Typography variant="h5">{site.siteMetadata.title}</Typography>
              </Box>
            </Box>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileSidebar}
              className="hide-sm-up"
            >
              <MenuIcon />
            </IconButton>
            <div className="hide-sm-down">{navigation}</div>
          </Box>
        </Toolbar>
        <div className="hide-sm-up">{mobileDrawer}</div>
      </AppBar>
      <SearchDialog
        isOpen={searchIsOpen}
        onClose={() => setSearchIsOpen(false)}
      />
    </>
  )
}

export default Header
