import { createMuiTheme } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"

const theme = createMuiTheme({
  typography: {
    h3: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: "bold",
      color: grey[700],
    },
    fontFamily: "Rubik",
  },
})

export default theme
